// import { QueryCache } from "react-query";
import * as auth from "../providers/auth-provider";

const client = async (
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {}
) => {
  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,

    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  return window.fetch(`${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      // call the refresh-tokens endpoint, get the tokens, then re-make the request with new token

      if (config.headers.Authorization !== undefined) {
        await auth.logout();
        console.log("signing out??");
      }
      window.location.assign(window.location);
      return Promise.reject({ message: "Please re-authenticate. " });
    }
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
};

// a GET request with pagination params specific for querying specific page & pageLength of jobs
const query = async (
  endpoint,
  params,
  { data, token, headers: customHeaders, ...customConfig } = {}
) => {
  const config = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  // console.log("params  : ", params);
  if (params) {
    endpoint = new URL(endpoint);
    endpoint.search = new URLSearchParams(params).toString();
  }

  return window.fetch(`${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      if (config.headers.Authorization !== undefined) {
        await auth.logout();
        console.log("signing out??");
      }

      window.location.assign(window.location);
      return Promise.reject({ message: "Please re-authenticate. " });
    }
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
};

const restDelete = async (
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {}
) => {
  const config = {
    method: "DELETE",
    data: data ? JSON.stringify(data) : undefined,

    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  return window.fetch(`${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      // call the refresh-tokens endpoint, get the tokens, then re-make the request with new token

      if (config.headers.Authorization !== undefined) {
        await auth.logout();
        console.log("signing out??");
      }
      window.location.assign(window.location);
      return Promise.reject({ message: "Please re-authenticate. " });
    }
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
};

export { client, query, restDelete };
