import React from "react";
import { Spinner } from "react-bootstrap";

const FullPageSpinner = () => {
  return (
    <div
      style={{
        // backgroundColor: palette.background,
        fontSize: "4em",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="grow" variant="primary" size="lg" />
    </div>
  );
};

export { FullPageSpinner };
