const localStorageKey = "__auth_provider_token__";
const localStorageRefreshKey = "__auth_provider_refresh_token__";
let apiURL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // development build code
  apiURL = "http://localhost:4000/v1";
} else {
  // production build code
  apiURL = "https://cityscape-api.herokuapp.com/v1";
}
const getToken = async () => {
  const refreshToken = window.localStorage.getItem(localStorageRefreshKey);
  const token = window.localStorage.getItem(localStorageKey);
  return { refreshToken, token };
};

export const handleUserResponse = ({ user, tokens }) => {
  window.localStorage.setItem(localStorageKey, tokens.access.token);
  window.localStorage.setItem(localStorageRefreshKey, tokens.refresh.token);
  window.location.assign(`${window.location.origin}/dashboard`);
  // window.location.href = `${window.location.origin}/dashboard`;
  return user;
};

const login = ({ email, password }) => {
  return client("login", { email, password })
    .then(handleUserResponse)
    .catch((err) => {
      console.log("loginerror", err);
    });
};

const register = ({ fname, lname, email, password }) => {
  return client("signup", { fname, lname, email, password }).then(
    handleUserResponse
  );
};

const logout = async () => {
  window.localStorage.removeItem(localStorageKey);
  window.location.assign(`${window.location.origin}/`);
};

const authURL = apiURL + "/auth";

const client = async (endpoint, data) => {
  console.log("DATA: ", data);
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data}`,
    },
  };

  console.log("config: ", config);

  console.log("apiURL: ", apiURL);

  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        console.log("userId: ", data.user.id);
        console.log(data.tokens.access);
        return data;
      } else {
        logout();
        return Promise.reject(data);
      }
    });
};

export { getToken, login, register, logout, localStorageKey };
